import React from 'react';
import StudioLayout from "../../components/studio/studioLayout"
import Year from '../../components/studio/year';


const Year2015 = () =>
{
    const imageData = [
        // {
        //     type: "video",
        //     url: "https://res.cloudinary.com/dmgjq19hi/video/upload/v1567268152/2015_xxoseu.mp4",
        //     name: "2015",
        //     width: 763
        // },
        {
            name: "p1",
            width: 315,
        },
        {
            name: "p2",
            width: 420,
        },
        {
            name: "p3",
            width: 560,
        },
        {
            name: "p4",
            width: 420,
        },
        {
            name: "p5",
            width: 236,
        },
        {
            name: "p6",
            width: 420,
        },
        {
            name: "p7",
            width: 315,
        },
        {
            name: "p8",
            width: 577,
        },
        {
            name: "p9",
            width: 420,
        },
        {
            name: "p10",
            width: 420,
        },
        {
            name: "p11",
            width: 560,
        },
        {
            name: "p12",
            width: 315,
        },
        {
            name: "p13",
            width: 420,
        },
        {
            name: "p14",
            width: 738,
        },
        {
            name: "p15",
            width: 420,
        },
        {
            name: "p16",
            width: 420,
        },
        {
            name: "p17",
            width: 630,
        },
        {
            name: "p18",
            width: 630,
        },


    ]


    return (
        <StudioLayout year={2015}>
            <Year year={15} imageData={imageData} />
        </StudioLayout>
    );
}

export default Year2015;